<template>
  <PageWithLayout>
    <div class="section_comm">
      <div class="head_section">
        <h2 class="tit_section">업체(평가)관리</h2>
      </div>
      <div class="body_section">
        <TableViewWithTitle title="평가 정보">
          <template v-slot:colgroup>
            <col style="width: 192px" />
            <col style="width: 267px" />
            <col style="width: 192px" />
            <col style="width: 267px" />
          </template>
          <template v-slot:body>
            <tr>
              <th>평가구분</th>
              <td>{{ templateType }}</td>
              <th>상태</th>
              <td>{{ activeType }}</td>
            </tr>
            <tr>
              <th>평가번호</th>
              <td colspan="3">{{ itemData.templateId }}</td>
            </tr>
            <tr>
              <th>평가명</th>
              <td colspan="3">{{ itemData.templateName }}</td>
            </tr>
            <tr>
              <th>평가정보</th>
              <td colspan="3">{{ itemData.templateDesc }}</td>
            </tr>
          </template>
        </TableViewWithTitle>

        <TableViewWithTitle title="항목 정보">
          <template v-slot:colgroup>
            <col style="width: 328px" />
            <col style="width: 720px" />
            <!--            <col style="width: 192px" />-->
          </template>
          <template v-slot:header>
            <tr>
              <th>항목명</th>
              <th>항목내용</th>
              <!--              <th>가중치</th>-->
            </tr>
          </template>
          <template v-slot:body>
            <template v-if="itemData.questList">
              <tr v-for="quest in itemData.questList" :key="quest.questNum">
                <td>{{ quest.question }}</td>
                <td>
                  <template v-if="quest.optionList">
                    <TableViewWithTitle title="" tableViewClass="tbl_inner">
                      <template v-slot:colgroup>
                        <col style="width: 52px" />
                        <col style="width: 540px" />
                        <col style="width: 128px" />
                      </template>
                      <template v-slot:header>
                        <tr>
                          <th>No</th>
                          <th>선택항목</th>
                          <th>점수</th>
                        </tr>
                      </template>
                      <template v-slot:body>
                        <SystemPerformanceOptionListLine
                          v-for="(option, idx) in quest.optionList"
                          :key="idx"
                          :option="option"
                          :idx="idx"
                        />
                      </template>
                    </TableViewWithTitle>
                  </template>
                </td>
                <!--                <td class="align_center">{{ quest.importance }}%</td>-->
              </tr>
            </template>
            <tr v-else>
              <td colspan="2">항목이 없습니다.</td>
            </tr>
          </template>
        </TableViewWithTitle>
      </div>
    </div>
    <Sticky>
      <div class="area_left">
        <button type="button" class="btn_fourthly btn_large" @click="onClickGoToList">목록</button>
      </div>
      <div class="area_right">
        <button type="button" class="btn_secondary btn_large" @click="onClickModify">수정</button>
      </div>
    </Sticky>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from "@/components/layout/PageWithLayout";
import TableViewWithTitle from "@/components/shared/tableView/TableViewWithTitle";

import FileView from "@/components/common/file/FileView";
import TableHead from "@/components/shared/TableHead";
import ConnectView from "@/components/common/connect/ConnectView";
import Board from "@/components/common/board/Board";
import Sticky from "@/components/layout/content/Sticky.vue";
import AlertPopup from "@/components/layout/popup/AlertPopup";

import PageMixin from "@/mixins/PageMixin";
import ApiService from "@/services/ApiService";

import { PROGRESS_START_ACTION, PROGRESS_END_ACTION } from "@/store/modules/progressbar/action";
import SystemPerformanceOptionListLine from "@/components/admin/system/performance/SystemPerformanceOptionListLine";

export default {
  name: "PerformanceView",
  components: {
    SystemPerformanceOptionListLine,
    PageWithLayout,
    TableViewWithTitle,

    TableHead,
    ConnectView,
    Board,
    FileView,
    Sticky,
    AlertPopup,
  },
  mixins: [PageMixin],
  data() {
    return {
      itemData: {},
      itemId: "",
      TEMPLATE_TYPE: {
        PA: "파트너평가",
        PE: "수행도평가",
      },
      optionMaxCnt: 5,
    };
  },
  computed: {
    // 평가구분
    templateType() {
      if (!this.itemData.templateType) return "";
      return this.TEMPLATE_TYPE[this.itemData.templateType];
    },
    // 상태
    activeType() {
      if (this.itemData.activeYn) {
        return "활성";
      } else {
        return "비활성";
      }
    },
  },
  beforeMount() {
    this.itemId = this.$route.params.id;

    this.$store.dispatch(PROGRESS_START_ACTION);

    this.getData();
  },
  methods: {
    async getData() {
      const path = `${this.$apiPath.ST_EVALUATE}/${this.itemId}`;
      const result = await ApiService.shared.getData(path);

      this.$store.dispatch(PROGRESS_END_ACTION);

      if (!result.data) return;

      this.itemData = result.data;
    },
    onAlert(text) {
      this.alert(text);
    },
    // 목록버튼
    onClickGoToList() {
      this.$router.push({
        path: this.$routerPath.SYSTEM_PERFORMANCE_SETTING,
        query: this.$route.query,
      });
    },
    // 수정버튼
    onClickModify() {
      this.$router.push({
        path: `${this.$routerPath.SYSTEM_PERFORMANCE_MODIFY}/${this.itemId}`,
      });
    },
  },
};
</script>
<style>
.tbl_comm .tbl_inner {
  border-top-color: #e1e1e1;
  margin-top: -8px;
}
.tbl_comm .tbl_inner thead th {
  text-align: center;
  border-right: 0;
}
</style>

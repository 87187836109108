<template>
  <tr>
    <td class="align_center">
      {{ idx + 1 }}
    </td>
    <td>
      {{ selection }}
    </td>
    <td class="align_center">
      {{ option.score }}
    </td>
  </tr>
</template>
<script>
export default {
  name: "SystemPerformanceOptionListLine",
  props: {
    option: Object,
    idx: [Number, String],
  },
  computed: {
    selection() {
      if (!this.option.optionType) return "";
      if (this.option.optionType === "M") return "주관식";
      return this.option.selection;
    },
  },
};
</script>
